.marketBanner {
    .contentCol {
        background: #272c34;
        border: 1px solid #3f3f40;
        border-radius: 4px;
        -webkit-flex: 1 1;
        flex: 1 1;
        margin: 15px;
        text-align: center;
        color: #ffffff;
    }
    p {
        color: #fff;
        opacity: .6;
        letter-spacing: .03em;
        line-height: 1.7;
    }
    h3 {
        margin-bottom: .2rem;
        font-weight: 600!important;
        line-height: 1.25!important;
        font-size: 1.2rem!important;
        color: #fff;
        letter-spacing: .03em;
        text-align: center;
        text-transform: capitalize!important;
    }
}
.marketBannerForDesktop {
    display: block;
    padding-top: 30px;
}
.marketBannerForMobile {
    display: none;
    padding-top: 30px;
}

/* mobile  */

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .marketBannerForDesktop {
        display: none;
    }
    .marketBannerForMobile {
        display: block;
    }
}

/*  Tab  */
@media (min-width: 768px) and (max-width: 1024px){
.marketBanner {
    .contentCol {
        background: #272c34;
        border: 1px solid #3f3f40;
        border-radius: 4px;
        -webkit-flex: 1 1;
        flex: 1 1;
        margin: 5px;
        text-align: center;
        color: #ffffff;
    }
    p {
        color: #fff;
        opacity: 0.6;
        letter-spacing: 0.03em;
        line-height: 1.5;
        font-size: 15px;
    }
    h3 {
        margin-bottom: .2rem;
        font-weight: 600!important;
        line-height: 1.25!important;
        font-size: 1.0rem!important;
        color: #fff;
        letter-spacing: .03em;
        text-align: center;
        text-transform: capitalize!important;
    }
}
}